import React, { useContext, useEffect, useState } from "react";
import {
  get_consultant_data_localStorage,
  get_project_info_localStorage,
  _get_admin_time_zone_localStorage,
  _get_client_dashboard_setting_localStorage,
  _get_content_setting_localStorage,
  _get_home_event_localStorage,
  _get_navbar_list_localStorage,
  _get_testmonial_localStorage,
  _get_user_from_localStorage,
  _wheel_of_life_setting_localStorage,
} from "src/DAL/localStorage/localStorage";

import io from "socket.io-client";
import { socketBaseUri } from "src/config/config";
const CreateContentSetting = React.createContext();
const content_setting = _get_content_setting_localStorage();
const client_dashboard_setting = _get_client_dashboard_setting_localStorage();
const get_testmonial_localStorage = _get_testmonial_localStorage();
const get_home_event_localStorage = _get_home_event_localStorage();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_project_info = get_project_info_localStorage();
const _get_user_info = _get_user_from_localStorage();
const _get_consultant_data = get_consultant_data_localStorage();
const get_navbar_list = _get_navbar_list_localStorage();
const wheel_of_life_setting = _wheel_of_life_setting_localStorage();

let socket = {};
// socket-initialization
socket = io(socketBaseUri + `?user_id=${_get_user_info._id}`);
//----------------------
export const useContentSetting = () => useContext(CreateContentSetting);
export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const [contentSettingData, setContentSettingData] = useState(content_setting);
  const [jsCode, setJSCode] = useState();
  const [cssCode, setCssCode] = useState();
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [feedSetting, setFeedSetting] = useState(null);
  const [affiliateUrlName, setAffiliateUrlName] = useState("");
  const [stripeKey, setStripeKey] = useState("");
  const [projectInfo, setProjectInfo] = useState(get_project_info);
  const [userInfo, setUserInfo] = useState(_get_user_info);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [consultantInfo, setConsultantInfo] = useState(_get_consultant_data);
  const [wheelOfLifeSetting, setWheelOfLifeSetting] = useState(
    wheel_of_life_setting
  );
  const [navbarList, setNavbarList] = useState(get_navbar_list);

  const [dashboardEventData, setDashboardEventData] = useState(
    get_home_event_localStorage
  );
  const [dashboardTestimonialData, setDashboardTestimonialData] = useState(
    get_testmonial_localStorage
  );
  const [dashboardSettingData, setDashboardSettingData] = useState(
    client_dashboard_setting
  );

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  const handleContentSettingData = (val) => {
    setContentSettingData(val);
  };
  const handleJSCode = (val) => {
    console.log(val, "val");
    setJSCode(val);
  };
  const handleCSSCode = (val) => {
    console.log(val, "val");

    setCssCode(val);
  };
  const handleDashboardSettingData = (val) => {
    setDashboardSettingData(val);
  };
  const handleDashboardTestimonialData = (val) => {
    setDashboardTestimonialData(val);
  };
  const handleDashboardEventData = (val) => {
    setDashboardEventData(val);
  };
  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };
  const handleUserInfo = (val) => {
    setUserInfo(val);
  };
  const handleConsultantInfo = (val) => {
    setConsultantInfo(val);
  };
  const handleNavbarList = (val) => {
    setNavbarList(val);
  };
  const handleWheelOfLifeSetting = (val) => {
    setWheelOfLifeSetting(val);
  };

  const handleProjectInfo = (val) => {
    setProjectInfo(val);
  };

  const handleStripeKey = (val) => {
    setStripeKey(val);
  };

  const handleNotificationsCount = (val) => {
    setNotificationsCount(val);
  };

  const handleUnreadMessageCount = (val) => {
    setUnreadMessageCount(val);
  };

  const handleAffiliateUrlName = (val) => {
    setAffiliateUrlName(val);
  };

  const handleFeedSetting = (val) => {
    setFeedSetting(val);
  };

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(socketBaseUri + `?user_id=${user_id}`);
  };

  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };

  useEffect(() => {
    console.log(socket, "--socket");
    if (userInfo?._id && !isConnected) {
      socket.emit("live_event_room", userInfo._id);
      setIsConnected(true);
    }
    return () => {};
  }, [userInfo, isConnected]);

  const collection = {
    adminTimeZone,
    cssCode,
    jsCode,
    navbarList,
    consultantInfo,
    userInfo,
    projectInfo,
    dashboardEventData,
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    wheelOfLifeSetting,
    notificationsCount,
    unreadMessageCount,
    stripeKey,
    affiliateUrlName,
    feedSetting,
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    handleWheelOfLifeSetting,
    handleAdminTimeZone,
    handleUserInfo,
    handleConsultantInfo,
    handleNavbarList,
    handleProjectInfo,
    handleNotificationsCount,
    handleUnreadMessageCount,
    handleStripeKey,
    handleAffiliateUrlName,
    handleJSCode,
    handleCSSCode,
    handleFeedSetting,
    // socket instant
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
  };

  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
