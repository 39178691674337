import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import loadable from "@loadable/component";
import LoaderSuspense from "./components/Suspense/LoaderSuspense.jsx";

const DashboardLayout = loadable(() => import("./layouts/dashboard"), {
  fallback: <LoaderSuspense />,
});

import LogoOnlyLayout from "./layouts/LogoOnlyLayout";

export const ForgetPassword = loadable(
  () => import("./pages/Forget_Password/ForgetPassword"),
  {
    fallback: <LoaderSuspense />,
  }
);
const Programmes = loadable(() => import("./pages/Programmes/Programmes"), {
  fallback: <LoaderSuspense />,
});
const ProgrammesDetail = loadable(
  () => import("./pages/Programmes/ProgrammesDetail"),
  {
    fallback: <LoaderSuspense />,
  }
);
const GoalStatement = loadable(
  () => import("./pages/GoalStatement/GoalStatement"),
  {
    fallback: <LoaderSuspense />,
  }
);
const Vault = loadable(() => import("./pages/Vault/Vault"), {
  fallback: <LoaderSuspense />,
});
const Profile = loadable(() => import("./pages/Profile/Profile"), {
  fallback: <LoaderSuspense />,
});

const Pods = loadable(() => import("./pages/Pods/Pods"), {
  fallback: <LoaderSuspense />,
});

const Gratitude = loadable(() => import("./pages/Gratitude/Gratitude"), {
  fallback: <LoaderSuspense />,
});

const Quotes = loadable(() => import("./pages/Quotes/Quotes"), {
  fallback: <LoaderSuspense />,
});
const Memories = loadable(() => import("./pages/Memories/Memories"), {
  fallback: <LoaderSuspense />,
});
const MemoriesDetail = loadable(
  () => import("./pages/Memories/MemoriesDetail"),
  {
    fallback: <LoaderSuspense />,
  }
);
const Calender = loadable(() => import("./pages/Calender/Calender"), {
  fallback: <LoaderSuspense />,
});

const SupportTicket = loadable(
  () => import("./pages/SupportTickets/SupportTicket"),
  {
    fallback: <LoaderSuspense />,
  }
);
const LessonsDetail = loadable(
  () => import("./pages/Programmes/LessonsDetail"),
  {
    fallback: <LoaderSuspense />,
  }
);
const LessonListing = loadable(
  () => import("./pages/Programmes/LessonListing"),
  {
    fallback: <LoaderSuspense />,
  }
);
const ProgrammRecordingDetail = loadable(
  () => import("./pages/Programmes/ProgrammRecordingDetail"),
  {
    fallback: <LoaderSuspense />,
  }
);
const AffirmationList = loadable(
  () => import("./pages/Affirmations/AffirmationList"),
  {
    fallback: <LoaderSuspense />,
  }
);
const StudySessionRecordingDetail = loadable(
  () => import("./pages/StudySessions/StudySessionRecordingDetail"),
  {
    fallback: <LoaderSuspense />,
  }
);
const SupportTicketDetail = loadable(
  () => import("./pages/SupportTickets/SupportTicketDetail"),
  {
    fallback: <LoaderSuspense />,
  }
);
const ConsultantRecordings = loadable(
  () => import("./pages/ConsultantRecordings/ConsultantRecordings"),
  {
    fallback: <LoaderSuspense />,
  }
);
const Community = loadable(() => import("./pages/Community/Community"), {
  fallback: <LoaderSuspense />,
});
const LoginAsCustomer = loadable(() => import("./pages/LoginAsCustomer"), {
  fallback: <LoaderSuspense />,
});
const Welcome = loadable(() => import("./pages/Welcome/Welcome"), {
  fallback: <LoaderSuspense />,
});

const CategoriesList = loadable(
  () => import("./pages/Affirmations/CategoriesList"),
  {
    fallback: <LoaderSuspense />,
  }
);

const Chat = loadable(() => import("./pages/Chat/Chat"), {
  fallback: <LoaderSuspense />,
});

const Members = loadable(() => import("./pages/Members/Members"), {
  fallback: <LoaderSuspense />,
});

const Members2 = loadable(() => import("./pages/Members-2/Members-2"), {
  fallback: <LoaderSuspense />,
});

const AttitudeAssessment = loadable(
  () => import("./pages/AttitudeAssessment/AttitudeAssessment"),
  {
    fallback: <LoaderSuspense />,
  }
);
const AssessmentResult = loadable(
  () => import("./pages/AssessmentResults/AssessmentResults"),
  {
    fallback: <LoaderSuspense />,
  }
);
const LiveStream = loadable(() => import("./pages/Live-Stream/LiveStream"), {
  fallback: <LoaderSuspense />,
});

const Visioneering = loadable(
  () => import("./pages/Visioneering/Visioneering"),
  {
    fallback: <LoaderSuspense />,
  }
);
const Mediation = loadable(() => import("./pages/Mediation/Mediation"), {
  fallback: <LoaderSuspense />,
});
const LifeScript = loadable(() => import("./pages/Life-Script/Life-Script"), {
  fallback: <LoaderSuspense />,
});

const EditProfile = loadable(() => import("./pages/Profile/EditProfile"), {
  fallback: <LoaderSuspense />,
});
export const Login = loadable(() => import("./pages/Login"), {
  fallback: <LoaderSuspense />,
});
const NotFound = loadable(() => import("./pages/Page404"), {
  fallback: <LoaderSuspense />,
});

const StudySessionListing = loadable(
  () => import("./pages/StudySessions/StudySessionListing"),
  {
    fallback: <LoaderSuspense />,
  }
);
const PodsDetail = loadable(() => import("./pages/Pods/PodsDetail"), {
  fallback: <LoaderSuspense />,
});
const ManagePhotos = loadable(
  () => import("./pages/GoalStatement/ManagePhotos"),
  {
    fallback: <LoaderSuspense />,
  }
);
const Payment = loadable(() => import("./components/Payment/Payment"), {
  fallback: <LoaderSuspense />,
  resolveComponent: (components) => components.Payment,
});

const BillingCard = loadable(() => import("./components/Billing/BillingCard"), {
  fallback: <LoaderSuspense />,
});

const BillingDetail = loadable(
  () => import("./components/Billing/BillingDetails"),
  {
    fallback: <LoaderSuspense />,
  }
);
const AllTransaction = loadable(
  () => import("./components/Transactions/AllTransaction"),
  {
    fallback: <LoaderSuspense />,
  }
);
const TransactionInformation = loadable(
  () => import("./components/Transactions/TransactionInformation"),
  {
    fallback: <LoaderSuspense />,
  }
);
const ManageCard = loadable(
  () => import("./components/ChangeCard/ManageCard"),
  {
    fallback: <LoaderSuspense />,
  }
);
const ChangeCreditCard = loadable(
  () => import("./components/ChangeCard/ChangeCreditCard"),
  {
    fallback: <LoaderSuspense />,
  }
);

const NintyDayPlan = loadable(() => import("./pages/90DayPlan/NintyDayPlan"), {
  fallback: <LoaderSuspense />,
});

const NintyDayTracker = loadable(
  () => import("./pages/90DayPlan/NintyDayTracker"),
  {
    fallback: <LoaderSuspense />,
  }
);
const AddNewEarning = loadable(
  () => import("./pages/90DayPlan/AddNewEarning"),
  {
    fallback: <LoaderSuspense />,
  }
);
const PaymentRequests = loadable(
  () => import("./components/PaymentRequests/PaymentRequests"),
  {
    fallback: <LoaderSuspense />,
  }
);
const ActiveRequests = loadable(
  () => import("./components/PaymentRequests/ActiveRequests"),
  {
    fallback: <LoaderSuspense />,
  }
);
const StudySessionRecordingListing = loadable(
  () => import("./pages/StudySessions/StudySessionRecordingListing"),
  {
    fallback: <LoaderSuspense />,
  }
);
const VideoDetails = loadable(() => import("./pages/Community/VideoDetails"), {
  fallback: <LoaderSuspense />,
});
const PostDetails = loadable(() => import("./pages/Community/PostDetails"), {
  fallback: <LoaderSuspense />,
});
const FavouriteVideos = loadable(
  () => import("./pages/Community/FavouriteVideos"),
  {
    fallback: <LoaderSuspense />,
  }
);
const LiveEvents = loadable(() => import("./pages/LiveEvents/LiveEvents"), {
  fallback: <LoaderSuspense />,
});
const LiveEventsDetail = loadable(
  () => import("./pages/LiveEvents/LiveEventsDetail"),
  {
    fallback: <LoaderSuspense />,
  }
);
const LiveEventVideoDetails = loadable(
  () => import("./pages/Community/LiveEventVideoDetails"),
  {
    fallback: <LoaderSuspense />,
  }
);
const MeditationCategories = loadable(
  () => import("./pages/Meditations/MeditationCategories"),
  {
    fallback: <LoaderSuspense />,
  }
);
const MeditationsList = loadable(
  () => import("./pages/Meditations/MeditationsList"),
  {
    fallback: <LoaderSuspense />,
  }
);
const MeditationDetail = loadable(
  () => import("./pages/Meditations/MeditationDetail"),
  {
    fallback: <LoaderSuspense />,
  }
);
const Shop = loadable(() => import("./pages/DDShop/Shop"), {
  fallback: <LoaderSuspense />,
});
const ShopItemDetail = loadable(() => import("./pages/DDShop/ShopItemDetail"), {
  fallback: <LoaderSuspense />,
});
const ShopItemCheckout = loadable(
  () => import("./pages/DDShop/ShopItemCheckout"),
  {
    fallback: <LoaderSuspense />,
  }
);
const ChallengeListAdd = loadable(
  () => import("./pages/challenges/ChallengeListAdd"),
  {
    fallback: <LoaderSuspense />,
  }
);
const ShoppingCart = loadable(() => import("./pages/DDShop/ShoppingCart"), {
  fallback: <LoaderSuspense />,
});
const AssessmentQuestionsSection = loadable(
  () => import("./pages/QuestionsScreen/Assessment"),
  {
    fallback: <LoaderSuspense />,
  }
);
const MinimalLayout = loadable(() => import("./layouts/MinimalLayout"), {
  fallback: <LoaderSuspense />,
});
const ThankYou = loadable(() => import("./pages/QuestionsScreen/ThankYou"), {
  fallback: <LoaderSuspense />,
});
const QuestionsAndAssessment = loadable(
  () => import("./pages/QuestionsScreen/QuestionsAndAssessment"),
  {
    fallback: <LoaderSuspense />,
  }
);
const HabitsList = loadable(() => import("./pages/Habits/HabitsList"), {
  fallback: <LoaderSuspense />,
});
const DayWiseHabits = loadable(() => import("./pages/Habits/DayWiseHabits"), {
  fallback: <LoaderSuspense />,
});
const LoginByAdmin = loadable(() => import("./pages/LoginByAdmin"), {
  fallback: <LoaderSuspense />,
});
const ReactJitsi = loadable(() => import("./pages/ReactJitsi"), {
  fallback: <LoaderSuspense />,
});
const DynamiteDiary = loadable(
  () => import("./pages/DynamiteDiary/DynamiteDiary"),
  {
    fallback: <LoaderSuspense />,
  }
);
const DiaryList = loadable(() => import("./pages/DynamiteDiary/diarylist"), {
  fallback: <LoaderSuspense />,
});
const MobilePlayer = loadable(() => import("./pages/MobilePlayer"), {
  fallback: <LoaderSuspense />,
});
const SeekVideoPlayer = loadable(
  () => import("./components/ReactPlayers/SeekVideoPlayer"),
  {
    fallback: <LoaderSuspense />,
  }
);
const VisioneeringList = loadable(
  () => import("./pages/Visioneering/VisioneeringList"),
  {
    fallback: <LoaderSuspense />,
  }
);
const GratitudeDaily = loadable(
  () => import("./pages/GratitudeDaily/GratitudeDaily"),
  {
    fallback: <LoaderSuspense />,
  }
);
const GratitudeList = loadable(
  () => import("./pages/GratitudeDaily/GratitudeList"),
  {
    fallback: <LoaderSuspense />,
  }
);
const MeditationList = loadable(
  () => import("./pages/Mediation/MeditationList"),
  {
    fallback: <LoaderSuspense />,
  }
);
const PendingRequestsPayment = loadable(
  () => import("./components/PaymentRequests/PendingRequestsPayment"),
  {
    fallback: <LoaderSuspense />,
  }
);
const NintyDayTrackerOld = loadable(
  () => import("./pages/90DayPlan/NintyDayTrackerOld"),
  {
    fallback: <LoaderSuspense />,
  }
);
const Meditation = loadable(() => import("./pages/Meditation/Meditation"), {
  fallback: <LoaderSuspense />,
});
const MeditationPastList = loadable(
  () => import("./pages/Meditation/MeditationList"),
  {
    fallback: <LoaderSuspense />,
  }
);
const DashboardApp = loadable(() => import("./pages/DashboardApp"), {
  fallback: <LoaderSuspense />,
});
const MediationChallenge = loadable(
  () => import("./pages/Mediation/Mediation"),
  {
    fallback: <LoaderSuspense />,
  }
);
const MediationOld = loadable(
  () => import("./pages/MediationChallenge/Mediation"),
  {
    fallback: <LoaderSuspense />,
  }
);
const LifesscriptList = loadable(
  () => import("./pages/Life-Script/LifesscriptList"),
  {
    fallback: <LoaderSuspense />,
  }
);
const SourceSessionsListing = loadable(
  () => import("./pages/SourceSession/SourceSessionsListing"),
  {
    fallback: <LoaderSuspense />,
  }
);
const SourceSessionsRecordingList = loadable(
  () => import("./pages/SourceSession/SourceSessionsRecordingList"),
  {
    fallback: <LoaderSuspense />,
  }
);
const SourceSessionRecordingDetail = loadable(
  () => import("./pages/SourceSession/SourceSessionRecordingDetail"),
  {
    fallback: <LoaderSuspense />,
  }
);
const PaymentRequestAuth = loadable(
  () => import("./pages/PaymentRequestAuth/PaymentRequestAuth"),
  {
    fallback: <LoaderSuspense />,
  }
);
const FullPageLayout = loadable(() => import("./layouts/FullPageLayout"), {
  fallback: <LoaderSuspense />,
});
import { projectMode } from "./config/config";

// ----------------------------------------------------------------------
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/thesource"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};

const MoveToMissionControl = () => {
  return <Navigate to="/mission-control"> </Navigate>;
};
const MoveToLiveEvents = () => {
  return <Navigate to="/live-events"> </Navigate>;
};

export default function Router() {
  return (
    <Routes>
      {/* For Mobile Side */}

      <Route element={<MinimalLayout />}>
        <Route path="/live-stream/:topic/:token" element={<LiveStream />} />
      </Route>
      <Route path="/login-by-admin/:token" element={<LoginByAdmin />} />
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<MoveToMissionControl />} />
        <Route path="/dashboard-new" element={<MoveToMissionControl />} />
        <Route
          path="/mission-control"
          element={<DashboardApp feeds_type="general" />}
        />
        <Route path="/TestVideoPlayer" element={<SeekVideoPlayer />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route
          path="/thesource"
          element={<Community feeds_type="general" event_id="" />}
        />
        <Route path="/series-video/:video_id" element={<VideoDetails />} />
        <Route
          path="/series-video/favourite-videos"
          element={<FavouriteVideos />}
        />
        <Route
          path="/feed-detail/:post_id"
          element={<PostDetails feeds_type="general" />}
        />
        <Route path="/programmes" element={<Programmes />} />
        <Route path="/programmes/:slug" element={<ProgrammesDetail />} />
        <Route
          path="/programmes/:slug/:tab_slug"
          element={<ProgrammesDetail />}
        />
        <Route path="/ninety-day-plan" element={<NintyDayPlan />} />
        <Route path="/ninety-day-tracker" element={<NintyDayTracker />} />
        <Route path="/ninety-day-tracker1" element={<NintyDayTrackerOld />} />
        <Route path="/addnewearning" element={<AddNewEarning />} />
        <Route
          path="/programmes/:program_slug/lessons"
          element={<LessonListing />}
        />
        <Route path="/study-session" element={<StudySessionListing />} />
        <Route path="/source-session" element={<SourceSessionsListing />} />
        <Route
          path="/meditation-categories"
          element={<MeditationCategories />}
        />
        <Route path="/live-events" element={<LiveEvents />} />
        <Route path="/live-events/:id" element={<LiveEventsDetail />} />
        <Route
          path="/live-events/:id/:category_slug"
          element={<LiveEventsDetail />}
        />
        <Route
          path="/live-events/:event_id/video-detail/:video_id"
          element={<LiveEventVideoDetails />}
        />
        <Route
          path="/live-events/:event_id/feed-detail/:post_id"
          element={<PostDetails feeds_type="event" />}
        />
        {/* <Route path="/gratitude-daily" element={<GratitudeDaily />} /> */}
        <Route path="/gratitude-daily" element={<GratitudeDaily />} />
        <Route
          path="/gratitude-daily/add-gratitude-daily"
          element={<GratitudeDaily />}
        />
        <Route
          path="gratitude-daily/edit-gratitude-daily/:id"
          element={<GratitudeDaily />}
        />
        <Route
          path="/gratitude-daily/past-activities"
          element={<GratitudeList />}
        />
        <Route
          path="/study-session/:sesson_slug/study-session-recordings"
          element={<StudySessionRecordingListing />}
        />
        <Route
          path="/source-session/:sesson_id/source-session-recordings"
          element={<SourceSessionsRecordingList />}
        />
        <Route
          path="/meditation-categories/:category_slug/meditations"
          element={<MeditationsList />}
        />
        <Route path="/meditations" element={<MeditationList />} />
        <Route path="/money-script" element={<LifesscriptList />} />
        <Route path="/money-script/:id" element={<LifeScript />} />
        {/* <Route path="/meditations/:meditation_id" element={<MediationOld />} /> */}
        <Route
          path="/meditations/:meditation_id"
          element={<MediationChallenge />}
        />
        <Route
          path="/meditation-categories/:category_slug/meditations/:meditation_slug"
          element={<MeditationDetail />}
        />
        <Route
          path="/study-session/:sesson_slug/study-session-recording/:recording_slug"
          element={<StudySessionRecordingDetail />}
        />
        <Route
          path="/source-session/:sesson_id/source-session-recording/:recording_slug"
          element={<SourceSessionRecordingDetail />}
        />
        <Route path="/lessons/:lesson_slug" element={<LessonsDetail />} />
        <Route
          path="/recordings/:recording_slug"
          element={<ProgrammRecordingDetail />}
        />
        <Route
          path="/lessons-recordings/:recording_slug"
          element={<ProgrammRecordingDetail />}
        />
        <Route path="/goal-statement" element={<GoalStatement />} />
        {/* chat routes */}
        <Route path="/chat" element={<Chat />} />
        {/* ----------- */}
        <Route path="/vault" element={<Vault />} />
        <Route
          path="/consultant-recordings"
          element={<ConsultantRecordings />}
        />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment-request" element={<PaymentRequests />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/Billing" element={<BillingCard />} />
        <Route path="/change-card" element={<ChangeCreditCard />} />
        <Route path="/transaction" element={<AllTransaction />} />
        <Route
          path="/transaction/transaction-detail/:slug"
          element={<TransactionInformation />}
        />
        <Route path="/manage-card" element={<ManageCard />} />
        <Route
          path="/billing/billing-detail/:billslug"
          element={<BillingDetail />}
        />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/pods" element={<Pods />} />
        <Route path="/affirmations" element={<CategoriesList />} />
        <Route
          path="/affirmations/affirmation-list/:category_slug"
          element={<AffirmationList />}
        />
        <Route path="/pods/pods-detail/:pod_slug" element={<PodsDetail />} />
        <Route
          path="/pending-request/:request_slug"
          element={<PendingRequestsPayment />}
        />
        <Route
          path="/active-request/:request_slug"
          element={<ActiveRequests />}
        />
        <Route path="dynamite-diary" element={<DynamiteDiary />} />
        <Route path="dynamite-diary/past-activities" element={<DiaryList />} />
        <Route
          path="dynamite-diary/add-dynamite-diary"
          element={<DynamiteDiary />}
        />
        <Route
          path="dynamite-diary/edit-dynamite-diary/:id"
          element={<DynamiteDiary />}
        />
        <Route path="/inception" element={<MoveToLiveEvents />} />
        <Route
          path="/goal-statement/manage-photos"
          element={<ManagePhotos />}
        />
        {/* <Route path="/gratitude" element={<Gratitude />} /> */}
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/members" element={<Members />} />
        <Route path="/questions/assessment/thank-you" element={<ThankYou />} />
        <Route path="/members2" element={<Members2 />} />
        <Route path="/memories" element={<Memories />} />
        <Route path="/assessment" element={<AttitudeAssessment />} />
        <Route path="/assessment/results" element={<AssessmentResult />} />
        <Route
          path="/visioneering/:visioneering_id"
          element={<Visioneering />}
        />
        <Route path="/visioneering" element={<VisioneeringList />} />
        <Route path="/questions" element={<QuestionsAndAssessment />} />
        <Route path="/mediation" element={<Meditation />} />
        <Route path="/mediation/edit-meditation/:id" element={<Meditation />} />
        <Route
          path="/mediation/past-activities"
          element={<MeditationPastList />}
        />
        <Route path="/mediation/:meditation_id" element={<Mediation />} />
        <Route
          path="/questions/assessment"
          element={<AssessmentQuestionsSection />}
        />
        <Route
          path="/memories/memories_detail/:memory_slug"
          element={<MemoriesDetail />}
        />
        <Route path="/calender" element={<Calender />} />
        <Route path="/support-ticket" element={<SupportTicket />} />
        <Route path="/support-ticket/:id" element={<SupportTicketDetail />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:item_slug" element={<ShopItemDetail />} />
        <Route path="/shop/shopping-cart" element={<ShoppingCart />} />
        <Route
          path="/shop/checkouts/:item_slug"
          element={<ShopItemCheckout />}
        />
        <Route path="/challenge" element={<ChallengeListAdd />} />
        <Route path="/challenge/:challenge_id" element={<ChallengeListAdd />} />
        <Route path="/habits-tracker" element={<HabitsList />} />
        <Route
          path="/habits-tracker/day-wise-habits"
          element={<DayWiseHabits />}
        />
        <Route path="/ReactJitsi" element={<ReactJitsi />} />
        <Route path="/ReactJitsi/:room_id" element={<ReactJitsi />} />
      </Route>
      <Route element={<FullPageLayout />}>
        <Route path="/mobile-player/:url" element={<MobilePlayer />} />
        <Route
          path="/payment-request-auth/:request_slug"
          element={<PaymentRequestAuth />}
        />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login-as-customer" element={<LoginAsCustomer />} />
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
