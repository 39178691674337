import { Avatar, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { s3baseUrl } from "src/config/config";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import {
  get_date_with_user_time_zone,
  string_avatar,
} from "src/utils/constants";
import SendIcon from "@mui/icons-material/Send";
import {
  _add_chat,
  _delete_message,
  _get_chat_message,
  _send_message,
  _update_message,
} from "src/DAL/Chat/Chat";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode, urlify } from "src/utils/convertHtml";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { get_local_preview } from "src/utils/domUtils";
import { Icon } from "@iconify/react";
import { update_image_on_s3_for_chat } from "src/DAL/LiveEvents/LiveEvents";
import CustomPopover from "src/components/GeneralComponents/CustomPopover";
import CustomConfirmation from "src/components/GeneralComponents/CustomConfirmation";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

function ChatMessages({ is_popup }) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedChat,
    selectedUser,
    messagesList,
    loadMoreData,
    lastBookElementRef,
    isLoadingMore,
    count,
    setSelectedMessage,
    selectedMessage,
    setMessagesList,
    get_formatted_message,
    scroll_to_bottom_on_message,
    get_chat_history_list,
    setChatList,
  } = useChat();
  const { userInfo, socket } = useContentSetting();
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const receiver_profile = selectedChat?.receiver_profile;
  const my_profile = selectedChat?.my_profile;

  const handleUploadFile = (e) => {
    const _file = e.target.files[0];
    setFile(_file);
  };

  const handleRemoveFile = (e) => {
    setFile(null);
    setImage(null);
  };

  const handleCancelUpdate = async () => {
    setSelectedMessage(null);
    setMessage("");
    setFile(null);
    setImage(null);
    setIsUpdate(false);
  };

  const handleEdit = async (message_obj) => {
    setSelectedMessage(message_obj);
    setMessage(message_obj.message);
    setImage(message_obj.image);
    setIsUpdate(true);
  };

  const handleSendMessage = async (receiver_id) => {
    let image = "";
    let is_error = false;
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("width", 600);
      formData.append("height", 700);
      const result = await update_image_on_s3_for_chat(formData);

      if (result.code === 200) {
        image = result.image_path;
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        is_error(true);
      }
    }

    if (is_error) {
      return;
    }
    const postData = {
      receiver_id: receiver_id,
      message: message,
      image: image,
      x_sh_auth: localStorage.getItem("token"),
      chat_obj: { ...selectedChat },
    };
    socket.emit("send_chat_message", postData);
    setMessage("");
    setFile(null);
    setImage(null);
  };

  const handleSubmitMessage = async (e) => {
    e.preventDefault();

    if (isUpdate) {
      handleUpdateMessage();
      return;
    }

    if (selectedUser) {
      handleSendMessage(selectedUser._id);
    } else {
      handleSendMessage(selectedChat.receiver_profile._id);
    }
  };

  const handleDelete = async (message_obj) => {
    setSelectedMessage(message_obj);
    setOpenDelete(true);
  };

  const handleAgreeDelete = async () => {
    setOpenDelete(false);
    const postData = {
      chat_id: selectedMessage.chat_id,
      message_id: selectedMessage._id,
    };
    socket.emit("delete_chat_message", postData);
  };

  const handleUpdateMessage = async () => {
    let _image = image;
    let is_error = false;
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("width", 600);
      formData.append("height", 700);
      const result = await update_image_on_s3_for_chat(formData);

      if (result.code === 200) {
        _image = result.image_path;
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        is_error(true);
      }
    }

    if (is_error) {
      return;
    }

    const postData = {
      message_id: selectedMessage._id,
      message: message,
      image: _image,
    };

    setMessage("");
    setFile(null);
    setImage(null);
    setSelectedMessage(null);
    setIsUpdate(false);
    socket.emit("update_chat_message", postData);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDelete,
    },
  ];

  // handle new message from socket
  const handle_new_message = async (data) => {
    const new_message = get_formatted_message(data.message_obj);
    setMessagesList((old_messages) => [...old_messages, new_message]);
    scroll_to_bottom_on_message();
    get_chat_history_list({});
  };

  const handle_edit_message = async (data) => {
    const new_message = get_formatted_message(data.message_obj);
    setMessagesList((old_messages) => {
      return old_messages.map((message) => {
        if (message._id === data.message_obj._id) {
          return new_message;
        } else {
          return message;
        }
      });
    });
    setChatList((prev) => {
      return prev.map((chat) => {
        if (chat._id == new_message.chat_id) {
          return {
            ...chat,
            last_message: new_message.message,
            last_message_date_time: new_message.message_date_time,
            message_id: new_message._id,
          };
        } else {
          return chat;
        }
      });
    });
  };
  const handle_delete_message = async (data) => {
    setMessagesList((old_messages) => {
      return old_messages.filter((message) => message._id !== data.message_id);
    });
    if (data.is_last_message) {
      setChatList((prev) => {
        return prev.map((chat) => {
          if (chat._id == data.chat_id) {
            return {
              ...chat,
              last_message: data.message_obj.message,
              last_message_date_time: data.message_obj.message_date_time,
              message_id: data.message_obj._id,
            };
          } else {
            return chat;
          }
        });
      });
    }
  };

  useEffect(() => {
    handleCancelUpdate();
    socket.on("send_chat_message_event_for_sender", (data) => {
      handle_new_message(data);
    });
    socket.on("update_chat_message_event_for_sender", (data) => {
      handle_edit_message(data);
    });
    socket.on("delete_chat_message_event_for_sender", (data) => {
      handle_delete_message(data);
    });

    return () => {
      socket.off("send_chat_message_event_for_sender");
      socket.off("update_chat_message_receiver");
      socket.off("delete_chat_message_event_for_sender");
    };
  }, [selectedChat?._id]);

  return (
    <>
      <div>
        {isLoadingChat && (
          <div className="d-flex justify-content-center">
            <CircularProgress
              className=""
              style={{ width: 20, height: 20, marginTop: "25%" }}
              color="primary"
            />
          </div>
        )}

        {!selectedChat && (
          <div className="d-flex justify-content-center align-items-center no-chat-wrapper">
            {/* <img src={chat_banner_image} className="pt-5" width="80%" alt="" /> */}
            <div className="no-chat-div">
              <Icon
                className="no-chat-image"
                icon="material-symbols:chat-bubble-outline"
              />
            </div>
          </div>
        )}

        {!isLoadingChat && (
          <div>
            <div
              className="user-chat-box-body ps-2 pe-2"
              style={
                is_popup
                  ? {
                      height: "70vh",
                      paddingBottom: file ? 220 : 130,
                      overflow: isLoadingMore ? "hidden" : "scroll",
                    }
                  : {
                      height: "70vh",
                      paddingBottom: file ? 160 : 120,
                      overflow: isLoadingMore ? "hidden" : "scroll",
                    }
              }
            >
              {selectedChat &&
                !isLoadingChat &&
                count > messagesList.length && (
                  <div className="text-center">
                    {isLoadingMore ? (
                      <CircularProgress
                        className="mt-3"
                        onClick={loadMoreData}
                        style={{ width: 20, height: 20 }}
                        color="primary"
                      />
                    ) : (
                      <span
                        ref={lastBookElementRef}
                        onClick={loadMoreData}
                        id="load-more-messages"
                      >
                        <CircularProgress
                          className="mt-3"
                          onClick={loadMoreData}
                          style={{ width: 20, height: 20 }}
                          color="primary"
                        />
                      </span>
                    )}
                  </div>
                )}
              {messagesList.map((message_obj, i) => {
                return (
                  <>
                    {message_obj.is_self ? (
                      <div
                        className="w-100 d-flex justify-content-end control-dots"
                        key={message_obj._id}
                      >
                        <div className="chat-message-dots">
                          <CustomPopover
                            menu={MENU_OPTIONS}
                            data={message_obj}
                          />
                        </div>
                        <div className="chat-outgoing">
                          {message_obj.image && (
                            <img
                              className="mb-2"
                              src={s3baseUrl + message_obj.image}
                            ></img>
                          )}
                          <span
                            className="chat-message-space"
                            dangerouslySetInnerHTML={{
                              __html: urlify(htmlDecode(message_obj.message)),
                            }}
                          ></span>

                          <Tooltip
                            title={get_date_with_user_time_zone(
                              message_obj.createdAt,
                              "DD MMM YYYY [at] hh:mm A",

                              userInfo.time_zone,
                              userInfo.adminTimeZone
                            )}
                            arrow
                          >
                            <span className="message-time">
                              {get_date_with_user_time_zone(
                                message_obj.createdAt,
                                "DD-MM-YYYY hh:mm A",
                                userInfo.time_zone,
                                userInfo.adminTimeZone
                              )}
                            </span>
                          </Tooltip>
                        </div>
                        {!is_popup && (
                          <Avatar
                            src={s3baseUrl + my_profile?.profile_image}
                            style={{ height: 35, width: 35, marginTop: 10 }}
                          >
                            {string_avatar(my_profile?.first_name[0])}
                          </Avatar>
                        )}
                      </div>
                    ) : (
                      <div
                        className="w-100 d-flex justify-content-start"
                        key={message_obj._id}
                      >
                        {!is_popup && (
                          <Avatar
                            src={s3baseUrl + receiver_profile?.profile_image}
                            style={{ height: 35, width: 35, marginTop: 10 }}
                          >
                            {string_avatar(receiver_profile?.first_name[0])}
                          </Avatar>
                        )}
                        <div className="chat-incoming">
                          {message_obj.image && (
                            <img
                              className="mb-2"
                              src={s3baseUrl + message_obj.image}
                            ></img>
                          )}
                          <span
                            className="chat-message-space"
                            dangerouslySetInnerHTML={{
                              __html: urlify(htmlDecode(message_obj.message)),
                            }}
                          ></span>
                          <Tooltip
                            title={get_date_with_user_time_zone(
                              message_obj.createdAt,
                              "DD MMM YYYY [at] hh:mm A",

                              userInfo.time_zone,
                              userInfo.adminTimeZone
                            )}
                            arrow
                          >
                            <span className="message-time">
                              {get_date_with_user_time_zone(
                                message_obj.createdAt,
                                "DD-MM-YYYY hh:mm A",
                                userInfo.time_zone,
                                userInfo.adminTimeZone
                              )}
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="chat-right-bottom">
        {(receiver_profile || is_popup) && (
          <div className="chat-send-message-wrapper">
            {file && (
              <div className="me-3 mb-2">
                <div className="chat-preview-image chat-image">
                  <img
                    className="chat-preview-image"
                    src={get_local_preview(file)}
                    alt=""
                  />

                  <Icon
                    className="cross"
                    onClick={handleRemoveFile}
                    icon="charm:circle-cross"
                  />
                </div>
              </div>
            )}

            {image && (
              <div className="me-3 mb-2">
                <div className="chat-preview-image chat-image">
                  <img
                    className="chat-preview-image"
                    src={s3baseUrl + image}
                    alt=""
                  />

                  <Icon
                    className="cross"
                    onClick={handleRemoveFile}
                    icon="charm:circle-cross"
                  />
                </div>
              </div>
            )}
            <form
              onSubmit={handleSubmitMessage}
              className="d-flex align-items-center w-100"
            >
              <textarea
                rows={4}
                className="chat-send-input"
                type="text"
                value={message}
                style={{ height: is_popup ? "40px" : "100px" }}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write your message..."
                required={!file}
                autoFocus
              />

              {!file && !image && (
                <span className="">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="message-chat-upload-button"
                    style={{ display: "none" }}
                    name=""
                    onChange={handleUploadFile}
                  />
                  <label>
                    <IconButton
                      onClick={() => {
                        document.getElementById("message-chat-upload-button") &&
                          document
                            .getElementById("message-chat-upload-button")
                            .click();
                      }}
                      className="ms-2"
                    >
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                </span>
              )}

              <IconButton type="submit" className="ms-2">
                <SendIcon />
              </IconButton>

              {isUpdate && (
                <IconButton onClick={handleCancelUpdate} className="ms-2">
                  <CloseIcon />
                </IconButton>
              )}
            </form>
          </div>
        )}
      </div>
      <CustomConfirmation
        title={"Are you sure you want to delete this message?"}
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleAgreeDelete}
      />
    </>
  );
}

export default ChatMessages;
