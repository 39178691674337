import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LoaderSuspense() {
  const classes = useStyles();
  return <CircularProgress className={classes.loading} color="primary" />;
}

export default LoaderSuspense;
