import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_chat_message = async (url) => {
  const requestObj = {
    path: `${url}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _delete_message = async (chat_id, data) => {
  const requestObj = {
    path: `api/chat/delete_message/${chat_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const _get_chat_history = async () => {
  const requestObj = {
    path: `api/chat/list_chat`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _get_chat_list_history = async (data) => {
  const requestObj = {
    path: `api/chat/chat_with_event/list`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const _read_message = (chat_id) => {
  const requestObj = {
    path: `api/chat/read_message/${chat_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _add_chat = async (data) => {
  const requestObj = {
    path: `api/chat/add_chat`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const _update_message = async (chat_id, data) => {
  const requestObj = {
    path: `api/chat/update_message/${chat_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const _send_message = async (data) => {
  const requestObj = {
    path: `api/chat/send_message`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const _consultant_member_list_with_search = async (data) => {
  const requestObj = {
    path: `api/member/consultant_member_list/search`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };

  return invokeApi(requestObj);
};

export const _check_chat_existing = async (reciver_id) => {
  const requestObj = {
    path: `api/chat/check_chat_existing/${reciver_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
