import { Icon } from "@iconify/react";
import { Avatar, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { s3baseUrl } from "src/config/config";
import { string_avatar } from "src/utils/constants";
import { _add_chat, _get_chat_message, _send_message } from "src/DAL/Chat/Chat";
import { useEffect } from "react";

function UserBox(props) {
  const {
    users_list = [],
    handleSetSelectedChat,
    handleSetSelectedUser,
    online_members = [],
    offline_members = [],
    get_users_list_against_event,
    onlineMembersCount,
    offlineMembersCount,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const handleSelectUser = (user) => {
    if (user.chat) {
      handleSetSelectedChat(user.chat);
      handleSetSelectedUser(null);
    } else {
      handleSetSelectedChat(null);
      handleSetSelectedUser(user);
    }
  };

  const bottom_in = {
    hidden: {
      y: "100px",
      x: "0px",
      height: "0",
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      height: "60vh",
      y: "0px",
      x: "0px",
      transition: { delay: 0, duration: 0.5 },
    },
  };

  const get_members_list = (tab) => {
    if (tab === 1) {
      return online_members;
    } else {
      return offline_members;
    }
  };

  useEffect(() => {
    get_users_list_against_event("", searchInput);
  }, [searchInput]);

  return (
    <>
      {/* Listing */}
      <div className="user-box-wrapper">
        <div
          className="user-box-header d-flex align-items-center justify-content-between"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="user-box-heading">Event Users</div>{" "}
          <div className="">
            {isExpanded ? (
              <Icon icon="akar-icons:chevron-down" />
            ) : (
              <Icon icon="akar-icons:chevron-up" />
            )}
          </div>
        </div>

        {isExpanded && (
          <>
            <div className="mt-1 p-2">
              <div className="user-search-box">
                <Icon
                  fontSize={20}
                  className="chat-search-icon"
                  icon="akar-icons:search"
                />
                <input
                  className="user-search-input"
                  type="text"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>
            <Tabs
              value={selectedTab}
              onChange={(e, value) => setSelectedTab(value)}
              textColor="primary"
              indicatorColor="primary"
              variant="fullWidth"
            >
              <Tab value={1} label={`Online (${onlineMembersCount})`} />
              <Tab value={0} label={`Offline (${offlineMembersCount})`} />
            </Tabs>
          </>
        )}

        <motion.div
          variants={bottom_in}
          initial="hidden"
          animate={isExpanded ? "visible" : "hidden"}
          exit="hidden"
          className={"user-box-body-expand"}
        >
          <div className="list-users">
            {get_members_list(selectedTab).map((user, i) => (
              <div
                onClick={() => handleSelectUser(user)}
                className="chat-list-user hover-effect d-flex p-2"
                key={i}
              >
                <div className="user-profile">
                  <Avatar
                    src={s3baseUrl + user.profile_image}
                    style={{ height: 30, width: 30 }}
                  >
                    {string_avatar(user.first_name[0])}
                  </Avatar>
                  <div className={user.is_online ? "online" : "offline"}></div>
                </div>
                <div className="chat-profile-data">
                  <div className="chat-profile-name ps-2">
                    {user.first_name + " " + user.last_name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default UserBox;
